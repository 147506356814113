// https://forum.freecodecamp.org/t/display-image-from-local-json-js-object-using-react/408351/13
// https://www.pluralsight.com/guides/load-and-render-json-data-into-react-components
// role: 0 - public user, role: 1 - sub-committee, role: 2 - core-committee, role: 3 - teacher/admin, role 4 - passout

export const membersData = [
  {
    name: "Abhishek Singh",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/AbhishekSingh.jpg"),
    linkedin: "https://www.linkedin.com/in/abhishek-singh-16bbb9205",
    github: "https://github.com/abhisingh38203",
  },
  {
    name: "Abir Pal",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/AbirPal.jpg"),
    linkedin: "https://www.linkedin.com/in/abir-pal/",
    github: "https://github.com/Abirpal202049",
  },
  {
    name: "Akash Mukherjee",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/AkashMukherjee.jpg"),
    linkedin: "https://www.linkedin.com/in/memukherjee",
    github: "https://github.com/memukherjee",
    additional: "https://www.hackerrank.com/memukherjee",
  },
  {
    name: "Aman Khan",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/AmanKhan.jpeg"),
    linkedin: "https://www.linkedin.com/in/amankhan0701/",
    github: "https://github.com/iam-aman-khan",
    additional: "https://www.instagram.com/__.k.i.s.s.u.__/",
  },
  {
    name: "Aman Thakur",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/AmanThakur.jpg"),
  },
  {
    name: "Anupriya Kumari",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/AnupriyaKumari.jpeg"),
    github: "https://github.com/AnupriyaKumari",
  },
  {
    name: "Aritra Biswas",
    batch: 2025,
    role: 2,
    img: require("../Assets/members/AritraBiswas.jpg"),
    github: "https://github.com/Aritra777",
  },
  {
    name: "Aryan Kumar Tiwari",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/AryanTiwari.jpg"),
    linkedin: "https://www.linkedin.com/in/aryan-tiwari-636943207/",
    github: "https://github.com/Gitter-Aryan",
  },
  {
    name: "Ayan Karmakar",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/AyanKarmakar.jpg"),
  },
  {
    name: "Bibek Chakraborty",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/BibekChakraborty.jpg"),
    linkedin: "https://www.linkedin.com/in/bibek-chakraborty-014a551b7/",
    github: "https://github.com/BibekChakraborty",
  },
  {
    name: "Debdeep Banerjee",
    batch: 2025,
    role: 2,
    img: require("../Assets/members/DebdeepBanerjee.jpeg"),
    linkedin: "https://www.linkedin.com/in/debdeep-banerjee-04b39b220/",
    github: "https://github.com/Debdeep1",
  },
  {
    name: "Debjeet Upadhyay",
    batch: 2025,
    role: 1,
    img: require("../Assets/members/DebjeetUpadhyay.jpg"),
    linkedin: "https://www.linkedin.com/in/debjeet-upadhyay-a4762322a",
    github: "https://github.com/Deb666",
  },
  {
    name: "Debosmit Choudhury",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/DebosmitChoudhury.jpeg"),
    linkedin: "https://www.linkedin.com/in/debosmit-choudhury-38387287/",
    github: "https://github.com/debosmithoney",
  },
  {
    name: "Gaurav Gupta",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/GauravGupta.jpg"),
    linkedin: "https://www.linkedin.com/mwlite/in/gaurav-gupta-658602224",
    github: "https://github.com/Gauravgupta113",
  },
  {
    name: "Gourish Sadhu",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/GourishSadhu.jpg"),
  },
  {
    name: "Jaya Gupta",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/JayaGupta.png"),
    linkedin: "https://www.linkedin.com/in/jaya-gupta-9746331b0",
  },
  {
    name: "Jayanta Pandit",
    batch: 2025,
    role: 2,
    img: require("../Assets/members/JayantaPandit.jpeg"),
    linkedin: "https://www.linkedin.com/in/panditjay/",
    github: "https://github.com/jay-io/",
  },
  {
    name: "Kamran Hassan",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/KamranHassan.jpg"),
    linkedin: "https://www.linkedin.com/in/kamran-hassan-55b361128/",
    github: "https://github.com/kamran-hassan",
  },
  {
    name: "Mayank Kumar Singh",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/MayankKumarSingh.jpg"),
    linkedin: "https://www.linkedin.com/in/mayank-kumar-singh-8830871b6",
    github: "https://github.com/mksss51",
    additional: "https://auth.geeksforgeeks.org/user/mayankkumarsingh51/practice/",
  },
  {
    name: "Nilava Bepari",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/NilavaBepari.jpeg"),
    linkedin: "https://linkedin.com/in/nilava-bepari-26a885191",
    github: "https://github.com/NILAVABEPARI",
  },
  {
    name: "Pawan Singh",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/PawanSingh.jpg"),
    linkedin: "https://www.linkedin.com/in/pawan-singh89",
  },
  {
    name: "Pranay Gupta",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/PranayGupta.jpg"),
    linkedin: "https://www.linkedin.com/in/thepranaygupta/",
    github: "https://github.com/thepranaygupta",
    additional: "https://thepranaygupta.bio.link",
  },
  {
    name: "Purbayan Majumder",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/PurbayanMajumder.jpg"),
    linkedin: "https://www.linkedin.com/in/purbayan-majumder-2799631a3/",
    github: "https://github.com/Purbayan2014",
    additional: "https://app.hackthebox.com/profile/289496",
  },
  {
    name: "Rahul Mahato",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/RahulMahato.jpg"),
    linkedin: "https://www.linkedin.com/in/rahul-mahato-3274831a7/",
  },
  {
    name: "Rubina Das",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/RubinaDas.jpg"),
    linkedin: "https://www.linkedin.com/in/rubina-das-43a824169",
  },
  {
    name: "Saikat Mukherjee",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/SaikatMukherjee.jpg"),
    linkedin: "https://www.linkedin.com/in/saikat-mukherjee-2a925b1b7/",
    github: "https://github.com/saikatmu31",
  },
  {
    name: "Sayantanee Sinha",
    batch: 2025,
    role: 2,
    img: require("../Assets/members/SayantaneeSinha.jpg"),
    linkedin: "https://www.linkedin.com/in/sayantanee-sinha-407b9b230",
  },
  {
    name: "Siddharth Keshri",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/SiddharthKeshri.jpg"),
    linkedin: "https://www.linkedin.com/in/siddharth-k-8113b999",
  },
  {
    name: "Simran kumari",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/SimranKumari.jpg"),
  },
  {
    name: "Soumali Gorai",
    batch: 2025,
    role: 1,
    img: require("../Assets/members/SoumaliGorai.jpg"),
    linkedin: "https://www.linkedin.com/in/soumali-gorai-566b01220/",
    github: "https://github.com/soumali28",
    additional: "https://soumali.netlify.app/",
  },
  {
    name: "Soumita Hazra",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/SoumitaHazra.png"),
    linkedin: "https://www.linkedin.com/in/soumita-hazra-6a438018a",
    additional: "https://youtube.com/channel/UCTLbeUSX7bIgQ1wwuyqOH8g",
  },
  {
    name: "Soumya Banerjee",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/SoumyaBanerjee.jpg"),
    linkedin: "https://www.linkedin.com/in/soumya-banerjee-94428a1a1/",
    github: "https://github.com/soumya495",
    additional: "https://soumya495.github.io/Soumya-Portfolio/",
  },
  {
    name: "Soumyajit Das",
    batch: 2023,
    role: 1,
    img: require("../Assets/members/SoumyajitDas.JPG"),
    linkedin: "https://www.linkedin.com/in/pioneiro/",
    github: "https://github.com/pioneiro",
    additional: "https://pioneiro.github.io/pioneiro/",
  },
  {
    name: "Souvik Mandal",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/SouvikMandal.jpg"),
    linkedin: "https://www.linkedin.com/in/8bitsouvik",
    github: "https://github.com/8-bit-souvik",
    additional: "https://8-bit-souvik.github.io",
  },
  {
    name: "Subham Sharma",
    batch: 2024,
    role: 2,
    img: require("../Assets/members/SubhamSharma.jpeg"),
    linkedin: "https://www.linkedin.com/in/subhamengine/",
    github: "https://github.com/subhamengine",
    additional: "https://bio.link/subhamengine",
  },
  {
    name: "Udhavi verma",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/Udhaviverma.jpg"),
    linkedin: "https://www.linkedin.com/in/udhavi-verma-uv",
  },
  {
    name: "Ujjwal Kumar",
    batch: 2023,
    role: 2,
    img: require("../Assets/members/UjjwalKumar.jpg"),
    linkedin: "https://www.linkedin.com/in/ujjwal-kumar-5994121b6",
    github: "https://github.com/triggeredujjwal",
  },
  {
    name: "Varun Kedia",
    batch: 2022,
    role: 4,
    img: require("../Assets/members/VarunKedia.png"),
    linkedin: "https://www.linkedin.com/in/purplevarun/",
    github: "https://github.com/purplevarun",
  },
];
