import React from "react";

const AdminStats = () => {
  return (
    <>
      <center>
        <img
          src="https://res.cloudinary.com/sahebcloud/image/upload/v1649358135/Under_construction-amico_wltocj.svg"
          alt=""
          style={{
            width: "35%",
            alignItems: "center",
          }}
        />
      </center>
    </>
  );
};

export default AdminStats;
